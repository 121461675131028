<template>
  <v-app id="inspire" class="grey lighten-4">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" color="white" app>
      <v-list dense>
        <template v-for="item in items">
          <v-list-item v-if="item.child.length == 0" :key="item.text" :to="item.link" link color="secondary">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :value="false"
            :key="item.text"
          >
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </template>
            <v-list-item
              class="pl-10"
              v-for="childitem in item.child"
              :key="childitem.text"
              link
              :to="childitem.to"
            >
              <v-list-item-action>
                <v-icon>{{ childitem.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ childitem.text }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
      dense
      class="elevation-5"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 550px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">VBC Admin</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu :close-on-content-click="closeOnClick" :nudge-width="200" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon large v-bind="attrs" v-on="on">
            <v-avatar color="black" size="36">
              <span class="white--text headline">A</span>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="black" size="36">
                  <span class="white--text headline">A</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="user_data">{{user_data.username}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item to="/profile">
              <v-icon>mdi-account-cog</v-icon>
              <v-list-item-title class="ml-2">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item to="/change-password">
              <v-icon>mdi-lock-outline</v-icon>
              <v-list-item-title class="ml-2">Change password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-icon>mdi-logout</v-icon>
              <v-list-item-title class="ml-2">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Alert />
  </v-app>
</template>

<script>
import Alert from "@/components/Alert.vue";
export default {
  props: {
    source: String,
  },
  name: "App",
  components: {
    Alert,
  },
  data: () => ({
    dialog: false,
    drawer: null,
    loading: false,
    user_data: null,
    closeOnClick: true,
    items: [
      { icon: "mdi-view-list", text: "Category Management", link: "/category", child: [] },
      { icon: "mdi-account", text: "Vendor Management", link: "/vendor", child: [] },
      // {
      //   icon: "mdi-account-group",
      //   text: "User Management",
      //   link: "/users",
      //   child: []
      // },
      // {
      //   icon: "mdi-message-video",
      //   text: "Session Management",
      //   link: "/session",
      //   child: []
      // },
      // {
      //   icon: "mdi-badge-account-outline",
      //   text: "Assigned Representative",
      //   link: "/representative",
      //   child: []
      // },
      { icon: "mdi-image", text: "Object Management", link: "/object", child: [] },
      { icon: "mdi-home-assistant", text: "Booth Assignment", link: "/rooms", child: [] },
      // { icon: "mdi-help-circle", text: "Helpdesk", link: "/helpdesk", child: [] },
      { icon: "mdi-file-upload-outline", text: "File Uploader", link: "/file", child: [] },
      // { icon: "mdi-bell-ring-outline", text: "Notification", link: "/notification", child: [] },
      { icon: "mdi-email", text: "Email Templates", link: "/templates", child: [] },
      { icon: "mdi-card-bulleted-settings-outline", text: "Email Configuration", link: "/email-configuration", child: [] },
      { icon: "mdi-comment-question-outline", text: "Contact Inquiries", link: "/inquiry", child: [] },
      { icon: "mdi-head-check-outline", text: "Vendor Inquiries", link: "/vendor-inquiry", child: [] },
      { icon: "mdi-shape-outline", text: "EH Categories", link: "/hallway-categories/list", child: [] },
      {
        icon: "mdi-alpha-p-circle-outline",
        text: "Pages",
        child: [
          // {
          //   icon: "mdi-account-group-outline",
          //   text: "Team",
          //   to: "/team",
          // },
          {
            icon: "mdi-alpha-b-circle-outline",
            text: "Board",
            to: "/board",
          },
          { 
            icon: "mdi-format-quote-close",
            text: "Testimonial", 
            to: "/testimonial"
          },          
          {
            icon: "mdi-currency-usd",
            text: "Pricing",
            to: "/pricing",
          },
          {
            icon: "mdi-vector-combine",
            text: "Main Lobby",
            to: "/main-lobby",
          },
          {
            icon: "mdi-lock-pattern",
            text: "Exhibit hall",
            to: "/exhibit-hall",
          },
          {
            icon: "mdi-contacts",
            text: "Contact Us",
            to: "/contact-us",
          },
          {
            icon: "mdi-calendar-check",
            text: "Events",
            to: "/events",
          },
          {
            icon: 'mdi-account-group',
            text: "Who we are",
            to: "/whoweare"
          }
        ],
      },
    ],
  }),
  methods: {
    logout() {
      let _self = this
      this.$axios.post(`/auth/admin/logout`,{
        refreshToken : _self.user_data.tokens.refresh.token
      })
      .then(() => {
        localStorage.removeItem("vbc_admin");
        this.$router.push("/login");
      })
      .catch((e) => {
        console.log(e);
        _self.loading = false;
      });
      
    },
  },
  mounted: function () {
    this.user_data = JSON.parse(localStorage.getItem("vbc_admin"));
  },
};
</script>