<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="3">
            <v-img :src="FRONT_ASSETS + 'frontend/img/VBCEH_logo_transparent.png'" contain max-height="200"></v-img>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-2" :loading="loading">
              <v-toolbar color="primary" dark flat dense>
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Username"
                    name="login"
                    prepend-icon="mdi-account"
                    type="text"
                    v-model="email"
                    :error-messages="form_error.email"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    :error-messages="form_error.password"
                    @keyup.enter.native="login()"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <!-- <v-btn text color="primary" to="/forgot-password">Forgot Password</v-btn> -->
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  v-on:click="login()"
                  :loading="loading"
                  :disabled="loading"
                >Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      email: null,
      password: null,
      loading: false,
      data: {},
    };
  },
  methods: {
    login() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`auth/admin/login`, {
        email: this.email,
        password: this.password,
      }).then((response) => {
        _self.form_error = {};
        let response_data = response.data;
        console.log('response_data',response_data)
        if (response_data.status) {
          this.data = response_data.user_data;
          const userData = response_data.user_data
          userData.tokens = response_data.tokens
          localStorage.setItem("vbc_admin",JSON.stringify(userData));          
          this.$axios.defaults.headers.common = {
            Authorization: "Bearer " + response_data.tokens.access.token,
            Accept: "application/x-www-form-urlencoded",
          };
          _self.$router.push("/vendor");
        } else {
          let errors = response_data.error;
          for (let key in errors) {
            console.log(errors[key]);
            _self.form_error[errors[key].field] = errors[key].message;
          }
        }
        _self.loading = false;
      }).catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    },
  },
};
</script>